@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';

$orange-bright: #cc8100;
$red-dark: #651a1a;
$green-dark: #6a7029;
$brown-dull: #dfd4ba;
$brown-faded: #e6dfcc;

$primary: $orange-bright;
$secondary: $red-dark;

$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';
$headings-font-family: 'Merriweather', serif;
$headings-font-weight: 700;

@import 'bootstrap/scss/_variables';
